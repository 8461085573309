import * as megane from "meganets/dist/megane";
import { DigitalFormEditorApplication,FormViewController, FormViewControllerProps, AfterPrintDialogType } from "../../../src/main";
import { FormDefinition } from "../formData";
import { parse as parseURL } from "url";
import * as querystring from "querystring";
import "../../yokosuka/src/styles/main.scss";
import "./styles/main.scss";
import { DigitalApplicationDefinition } from "../../../src/DigitalForm/definition/form/DigitalApplicationDefinition";

declare var __BUILD_ID__: string;
declare var __IS_DEVSERVER__: boolean;
declare var __GOOGLE_MAP_API_KEY__: string;
declare var __ENV__: string;

const options: FormViewControllerProps["options"] = {
  buildID: __BUILD_ID__,
  googleMapAPIKey: __GOOGLE_MAP_API_KEY__,
  qrcodeNamePrefix: "横須賀市_",
  qrcodeFollowsPDF: true,
  qrcodeIgnoreNumberOfPages: true,
  filenameWithDate: true,
  afterPrintDialog: AfterPrintDialogType.None,
  debug: __ENV__ === "production"
};

const url = parseURL(location.href);
const query = querystring.parse(url.query);
const definition: DigitalApplicationDefinition = {
  pdfFileName: "横須賀市_手続きナビ書類",
  pdfMetaTitle: "横須賀市_手続きナビ",
  forms: []
};
const movingData: { [keys: string]: any } = {};
if (typeof query.forms === "string") {
  const string = decodeURIComponent(query.forms);
  const list = string.split(",");
  for (let i = 0, l = list.length; i < l; i++) {
    const index = parseInt(list[i]) - 1;
    definition.forms.push(FormDefinition.forms[index]);
  }
} else if (typeof query.formIDs === "string") {
  const string = decodeURIComponent(query.formIDs);
  const list = string.split(",");
  for (let i = 0, l = list.length; i < l; i++) {
    const idExpression = list[i];
    const idToken = idExpression.split("-");
    const id = idToken[0];
    if (id === "moving") {
      movingData["procedure_type"] = idToken[1];
    }
    const index = FormDefinition.forms.map(form => form.id).indexOf(id);
    if (index >= 0) {
      definition.forms.push(FormDefinition.forms[index]);
    }
  }
} else {
  definition.forms = FormDefinition.forms;
}
definition.extraSetup = FormDefinition.extraSetup;
definition.extraPages = FormDefinition.extraPages;
definition.onFinishPreview = FormDefinition.onFinishPreview;

const frame = new megane.ViewControllerFrame(document.getElementById("container"));
const templates = new megane.TemplateManager(document.getElementById("template"));
const app = new DigitalFormEditorApplication({
  formData: definition,
  frame,
  templates,
  options: {
    formViewControllerOptions: options
  }
});
app.onShowFormView = (formView: FormViewController) => {
  const forms = formView.digitalFormViewController.digitalForms;
  const movingForm = forms.filter(form => form.id === "moving")[0];
  if (!movingForm) return;
  movingForm.data = movingData;
}

window.addEventListener("load", () => {
  // @ts-ignore
  app.onAppReady();
});
window.addEventListener('beforeunload', (e) => {
  // @ts-ignore
  if (!__IS_DEVSERVER__) {
    e.returnValue = "編集中のデータが失われますがよろしいですか？";
  }
});