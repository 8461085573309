export const formTemplates = {
  "visa": {
    "kind": "select",
    "title": "在留資格",
    "options": [
      {
        "label": "--",
        "value": ""
      },
      {
        "label": "教授",
        "value": "教授"
      },
      {
        "label": "芸術",
        "value": "芸術"
      },
      {
        "label": "宗教",
        "value": "宗教"
      },
      {
        "label": "報道",
        "value": "報道"
      },
      {
        "label": "投資・経営",
        "value": "投資・経営"
      },
      {
        "label": "法律・会計業務",
        "value": "法律・会計業務"
      },
      {
        "label": "医療",
        "value": "医療"
      },
      {
        "label": "研究",
        "value": "研究"
      },
      {
        "label": "教育",
        "value": "教育"
      },
      {
        "label": "技術",
        "value": "技術"
      },
      {
        "label": "人文知識・国際業務",
        "value": "人文知識・国際業務"
      },
      {
        "label": "企業内転勤",
        "value": "企業内転勤"
      },
      {
        "label": "技能",
        "value": "技能"
      },
      {
        "label": "技能実習1号イ",
        "value": "技能実習1号イ"
      },
      {
        "label": "技能実習1号ロ",
        "value": "技能実習1号ロ"
      },
      {
        "label": "技能実習2号イ",
        "value": "技能実習2号イ"
      },
      {
        "label": "技能実習2号ロ",
        "value": "技能実習2号ロ"
      },
      {
        "label": "技能実習3号イ",
        "value": "技能実習3号イ"
      },
      {
        "label": "技能実習3号ロ",
        "value": "技能実習3号ロ"
      },
      {
        "label": "経営・管理",
        "value": "経営・管理"
      },
      {
        "label": "技術・人文知識・国際業務",
        "value": "技術・人文知識・国際業務"
      },
      {
        "label": "介護",
        "value": "介護"
      },
      {
        "label": "文化活動",
        "value": "文化活動"
      },
      {
        "label": "留学",
        "value": "留学"
      },
      {
        "label": "研修",
        "value": "研修"
      },
      {
        "label": "家族滞在",
        "value": "家族滞在"
      },
      {
        "label": "特定活動",
        "value": "特定活動"
      },
      {
        "label": "特別永住者",
        "value": "特別永住者"
      },
      {
        "label": "日本人の配偶者等",
        "value": "日本人の配偶者等"
      },
      {
        "label": "永住者の配偶者等",
        "value": "永住者の配偶者等"
      },
      {
        "label": "定住者",
        "value": "定住者"
      },
      {
        "label": "特定技能1号",
        "value": "特定技能1号"
      },
      {
        "label": "特定技能2号",
        "value": "特定技能2号"
      },
      {
        "label": "在留の資格なし",
        "value": "在留の資格なし"
      },
      {
        "label": "高度専門職1号イ",
        "value": "高度専門職1号イ"
      },
      {
        "label": "高度専門職1号ロ",
        "value": "高度専門職1号ロ"
      },
      {
        "label": "高度専門職1号ハ",
        "value": "高度専門職1号ハ"
      },
      {
        "label": "高度専門職2号",
        "value": "高度専門職2号"
      },
      {
        "label": "永住者",
        "value": "永住者"
      },
      {
        "label": "未取得",
        "value": "未取得"
      },
      {
        "label": "仮滞在",
        "value": "仮滞在"
      },
      {
        "label": "一時庇護",
        "value": "一時庇護"
      }
    ]
  },
  "school_grade": {
    "title": "学年",
    "kind": "select",
    "options": [
      {
        "label": "--",
        "value": ""
      },
      {
        "label": "小学校1年生",
        "value": "小1"
      },
      {
        "label": "小学校2年生",
        "value": "小2"
      },
      {
        "label": "小学校3年生",
        "value": "小3"
      },
      {
        "label": "小学校4年生",
        "value": "小4"
      },
      {
        "label": "小学校5年生",
        "value": "小5"
      },
      {
        "label": "小学校6年生",
        "value": "小6"
      },
      {
        "label": "中学校1年生",
        "value": "中1"
      },
      {
        "label": "中学校2年生",
        "value": "中2"
      },
      {
        "label": "中学校3年生",
        "value": "中3"
      }
    ]
  },
  "hou_30_45": {
    "title": "法第30条45規定区分",
    "kind": "radio",
    "options": [
      {
        "label": "中長期在留者",
        "value": "中長期在留者"
      },
      {
        "label": "特別永住者",
        "value": "特別永住者"
      },
      {
        "label": "一時庇護許可者",
        "value": "一時庇護許可者"
      },
      {
        "label": "仮滞在許可者",
        "value": "仮滞在許可者"
      },
      {
        "label": "出生による経過滞在者",
        "value": "出生による経過滞在者"
      },
      {
        "label": "国籍喪失による経過滞在者",
        "value": "国籍喪失による経過滞在者"
      }
    ]
  },
  "foreigner_field": {
    "kind": "field",
    "fields": [
      {
        "kind": "radio",
        "title": "在留カードやパスポートを持っていますか。",
        "id": "has_card",
        "options": [
          {
            "label": "在留カード、特別永住者証明書",
            "value": "有 "
          },
          {
            "label": "パスポート（在留カードの代わり）",
            "value": "有"
          },
          {
            "label": "なし",
            "value": "無"
          },
          {
            "label": "職権",
            "value": "職権"
          }
        ],
        "required": true
      },
      {
        "id": "nationality",
        "title": "国籍",
        "description": "国名を選択してください。",
        "kind": "template",
        "template": "select_country"
      },
      {
        "id": "qualification",
        "title": "在留資格",
        "kind": "template",
        "template": "visa",
        "required": true
      },
      {
        "kind": "template",
        "id": "mover_stay",
        "template": "simple_year_month",
        "required": false,
        "title": "在留期間"
      },
      {
        "id": "residence_type",
        "kind": "template",
        "template": "hou_30_45",
        "required": true
      },
      {
        "kind": "text",
        "id": "mover_card",
        "title": "在留カード番号",
        "min": 12,
        "max": 12,
        "placeholder": "例：AB12345678CD",
        "validator": {
          "kind": "SpecifiedLengthDigitsOnlyHankakuEisuji",
          "pattern": [12]
        },
        "required": true
      },
      {
        "kind": "template",
        "id": "period",
        "title": "在留期間満了日",
        "template": "date_in_japanese_calendar",
        "required": true,
        "context": {
          "eras": [
            {
              "name": "西暦",
              "value": "R",
              "min": 2000,
              "max": 2100
            }
          ]
        }
      }
    ]
  },
  "birthdate_universal_short": {
    "kind": "template",
    "title": "生年月日",
    "template": "date_in_japanese_calendar",
    "context": {
      "eras": [
        {
          "name": "明治",
          "value": "M",
          "max": 45
        },
        {
          "name": "大正",
          "value": "T",
          "max": 15
        },
        {
          "name": "昭和",
          "value": "S",
          "max": 64
        },
        {
          "name": "平成",
          "value": "H",
          "max": 31
        },
        {
          "name": "令和",
          "value": "R",
          "max": 3
        },
        {
          "name": "西暦",
          "value": "A.D.",
          "min": 1900,
          "max": 2021
        }
      ],
      "initialValue": {
        "gengo": "H",
        "year": "1",
        "month": "1",
        "date": "1"
      }
    }
  },
  "birthdate_gengo": {
    "kind": "template",
    "title": "生年月日",
    "template": "date_in_japanese_calendar",
    "context": {
      "eras": [
        {
          "name": "明治",
          "value": "明治",
          "max": 45
        },
        {
          "name": "大正",
          "value": "大正",
          "max": 15
        },
        {
          "name": "昭和",
          "value": "昭和",
          "max": 64
        },
        {
          "name": "平成",
          "value": "平成",
          "max": 31
        },
        {
          "name": "令和",
          "value": "令和",
          "max": 3
        }
      ],
      "initialValue": {
        "gengo": "H",
        "year": "1",
        "month": "1",
        "date": "1"
      }
    }
  },
  "address_set": {
    "kind": "array",
    "items": [
      {
        "id": "$(name)_zipcode",
        "kind": "template",
        "title": "郵便番号",
        "template": "zipcode",
        "required": true
      },
      {
        "kind": "template",
        "id": "$(name)_address",
        "title": "都道府県・市区町村・町名",
        "description":"かならず都道府県名から入力してください",
        "template": "address2",
        "refer": "$(name)_zipcode-raw",
        "validator":"ZenkakuOnly",
        "placeholder":"例：○○県○○市○○町"
      },
      {
        "kind":"text",
        "id":"$(name)_address_chouban",
        "title": "字名丁目・番地・号など",
        "validator":"ZenkakuOnly",
        "placeholder":"〇丁目〇-〇"
      },
      {
        "kind":"text",
        "id":"$(name)_address_building",
        "title": "建物名（方書）",
        "validator":"ZenkakuOnly",
        "placeholder":"例：ヨコスカマンションA館3号棟201号室"
      },
      {
        "kind":"text",
        "id":"target_$(name)_address",
        "hidden":true
      }
    ]
  }
};