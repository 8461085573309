interface FormRenderingSettingEjs {
    path: string,
    numberOfPages: number;
    extraPages?: ExtraPageDefinition[]
}

interface FormRenderingBindSimple {
    kind: "simple";
    x: number;
    y: number;
    width: number;
    height: number;
    bind: string;
}

type FormRenderingBind = FormRenderingBindSimple;

interface FormRenderingSettingJSON {
    kind: "json";
    binds: FormRenderingBind[];
    numberOfPages: number;
    extraPages?: ExtraPageDefinition[]
}

export interface ExtraPageDefinition {
    kind: "html" | "image";
    url: string;
}

export type FormRenderingSetting = FormRenderingSettingEjs | FormRenderingSettingJSON;

const isRenderingSettingEjs = (rendering: FormRenderingSetting): rendering is FormRenderingSettingEjs => {
    return !(rendering as any).kind;
}

export const buildRenderingFrame = (rendering: FormRenderingSetting): HTMLIFrameElement => {
    const iframe = document.createElement("iframe");
    iframe.setAttribute("sandbox", "allow-modals allow-same-origin allow-scripts");
    iframe.setAttribute("style", "display: none;");
    iframe.setAttribute("data-num-pages", String(rendering.numberOfPages));
    if (isRenderingSettingEjs(rendering)) {
        iframe.src = rendering.path;
    } else {
        iframe.srcdoc = `<html>
    <head>
        <style>
            span {
                position: absolute;
            }
        </style>
    </head>
    <body>
${rendering.binds.map(bind => {
            return `<span class="text-input" 
style="left: ${bind.x}px; top: ${bind.y}px; width: ${bind.width}px; height: ${bind.height}px"
data-bind="${bind.bind}"></span>`;
        }).join("")}</body>
</html>`;
    }
    return iframe;
}