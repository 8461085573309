import { ElementDefinition } from "../definition/element/ElementDefinition";
import { FieldObjectConcreteDefinition } from "../definition/element/FieldObjectConcreteDefinition";
import { DigitalFormBoxDefinition } from "../definition/form/DigitalFormBoxDefinition";
import { DigitalFormDefinition } from "../definition/form/DigitalFormDefinition";
import { DigitalFormBoxTemplate } from "./DigitalFormBoxTemplate";
import { DigitalFormTemplate } from "./DigitalFormTemplate";
import { FieldElementTemplate } from "./FieldElementTemplate";
import { FieldObjectTemplate } from "./FieldObjectTemplate";
import { TemplateManager } from "./TemplateManager";

export function resolveTemplateReference(reference: FieldElementTemplate): ElementDefinition[] {
    if (reference.kind === "array") return reference.items.flatMap(item => resolveTemplateReference(item));
    if (reference.kind === "field") {
        const copy = Object.assign({}, reference) as FieldObjectTemplate;
        copy.fields = copy.fields.flatMap(element => resolveTemplateReference(element));
        return [copy as FieldObjectConcreteDefinition];
    }
    if (reference.kind !== "template") return [reference];
    const definition = TemplateManager.getTemplate(reference.template);
    const copy = Object.assign({}, reference);
    delete copy.template;
    delete copy.kind;
    let copiedDefinition = Object.assign({}, definition);
    if (copy.args) {
        let copiedDefinitionString = JSON.stringify(copiedDefinition);
        Object.entries(copy.args).forEach(entry => {
            copiedDefinitionString = copiedDefinitionString.replace(new RegExp(`\\$\\(${entry[0]}\\)`, "g"), entry[1]);
        });
        copiedDefinition = JSON.parse(copiedDefinitionString);
    }
    if (copiedDefinition.kind === "field" && copy.index !== undefined) {
        copiedDefinition.fields = (copiedDefinition.fields as (FieldElementTemplate | ElementDefinition)[]).flatMap(field => {
            if (field.kind === "array") return resolveTemplateReference(reference);
            const newField = Object.assign({}, field);
            if(field.id) {
                newField.id = `${field.id}_${reference.index}`;
            }
            return resolveTemplateReference(newField);
        });
        delete copy.index;
    }
    return resolveTemplateReference(Object.assign(copiedDefinition, copy));
}

export const resolveBoxDefinition = (definition: DigitalFormBoxTemplate): DigitalFormBoxDefinition => {
    const copy = Object.assign({}, definition);
    copy.elements = definition.elements.flatMap(element => resolveTemplateReference(element));
    return copy as DigitalFormBoxDefinition;
}

export const resolveDigitalFormDefinition = (definition: DigitalFormTemplate): DigitalFormDefinition => {
    const copy = Object.assign({}, definition);
    copy.boxes = definition.boxes.map(box => resolveBoxDefinition(box));
    return copy as DigitalFormDefinition;
}