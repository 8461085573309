import { FormElement } from "./FormElement";
import { DOMTemplate } from "../../denki";
import { node } from "../Util";
import { FieldInputElement } from "./FieldInputElement";
import { isNumber } from "util";

export class FormNumberElement extends FormElement<number> implements FieldInputElement {
  public readonly fieldsElement: HTMLElement;
  public readonly prefixElement: HTMLLabelElement;
  public readonly suffixElement: HTMLLabelElement;
  public readonly numberElement: HTMLInputElement;
  public readonly incrementButton: HTMLButtonElement;
  public readonly decrementButton: HTMLButtonElement
  private _min?: number;
  private _max?: number;
  private _initialValue: number = 0;

  constructor(name: string, template: DOMTemplate) {
    super(name, template.get("field-element"));
    this.fieldsElement = this.element.querySelector("[data-role=elements]");
    const id = name;
    this.virtualInput.setAttribute("data-id", id);
    this.virtualInput.setAttribute("name", id);
    this.virtualInput.addEventListener("change", () => {
      const value = this.value;
      if (isNumber(value) && isFinite(value)) {
        this.numberElement.value = String(value);
      } else {
        this.numberElement.value = String(this._initialValue);
      }
    });
    const attributes = {
      type: 'text',
      name,
      'readonly': 'true',
      placeholder: "0",
      style: "pointer-events: none"
    };
    let numberElement = node('input', attributes);
    this.numberElement = numberElement;

    const fieldset = node('div', { class: 'input-number' });
    fieldset.appendChild(this.prefixElement = node('label', {}, ""));
    fieldset.appendChild(numberElement);
    fieldset.appendChild(this.suffixElement = node('label', {}, ""));
    let crementWrapper = node('div');
    const decrementButton = node('button', { class: 'decrementButton' }, 'ー');
    decrementButton.addEventListener('click', () => {
      this.decrement();
    });
    crementWrapper.appendChild(decrementButton);
    this.decrementButton = decrementButton;
    const incrementButton = node('button', { class: 'incrementButton' }, '＋');
    incrementButton.addEventListener('click', () => {
      this.increment();
    });
    this.incrementButton = incrementButton;
    crementWrapper.appendChild(incrementButton);
    fieldset.appendChild(crementWrapper);
    this.fieldsElement.appendChild(fieldset);
  }

  public get min() {
    return this._min;
  }

  public set min(min: number | undefined) {
    this._min = min;
  }

  public get max() {
    return this._max;
  }

  public set max(max: number | undefined) {
    this._max = max;
  }

  public get initialValue() {
    return this._initialValue;
  }

  public set initialValue(initialValue: number) {
    this._initialValue = initialValue;
    this.numberElement.placeholder = String(initialValue);
  }

  protected onUpdateValue(value: number) {
    this.decrementButton.disabled = value <= this._min;
    this.incrementButton.disabled = value >= this._max;
  }

  public increment() {
    const numberElement = this.numberElement;
    const currentValue = parseInt(numberElement.value);
    if (isFinite(currentValue)) {
      if (isFinite(this._max)) {
        if (this._max > currentValue) {
          numberElement.value = String(currentValue + 1);
        }
      } else {
        numberElement.value = String(currentValue + 1);
      }
    } else {
      numberElement.value = String(this._initialValue);
    }
    this.value = parseInt(numberElement.value);
  }

  public decrement() {
    const numberElement = this.numberElement;
    const currentValue = parseInt(numberElement.value);
    if (isFinite(currentValue)) {
      if (isFinite(this._min)) {
        if (this._min < currentValue) {
          numberElement.value = String(currentValue - 1);
        }
      } else {
        numberElement.value = String(currentValue - 1);
      }
    } else {
      numberElement.value = String(this._initialValue);
    }
    this.value = parseInt(numberElement.value);
  }

  public focus() {
    this.numberElement.focus();
  }
}