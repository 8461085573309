import { FormElement } from "./FormElement";
import { DOMTemplate } from "../../denki";
import { node } from "../Util";
import { FieldInputElement } from "./FieldInputElement";
import { OptionType } from "../definition/OptionType";

export class FormSelectElement extends FormElement implements FieldInputElement {
  public readonly fieldsElement: HTMLElement;
  public readonly selectElement: HTMLSelectElement;
  private readonly map: { [key: string]: HTMLInputElement } = {};

  constructor(name: string, options: OptionType[], template: DOMTemplate) {
    super(name, template.get("field-element"));
    this.fieldsElement = this.element.querySelector("[data-role=elements]");

    const id = name;
    const select = <HTMLSelectElement>node('select', { id });
    for (let i = 0, l = options.length; i < l; i++) {
      const option = options[i];
      const [value, label] = (() => {
        if (typeof option === "string") {
          return [option, option];
        } else {
          return [option.value, option.label];
        }
      })();
      const optionName = `${name}-${value}`;
      const elementId = optionName;
      select.appendChild(node('option', {
        'data-id': elementId,
        'data-name': optionName,
        value
      }, label));
    }
    select.addEventListener("change", e => {
      this.value = select.value;
    });
    this.virtualInput.addEventListener("change", () => {
      select.value = this.value;
    });
    this.virtualInput.setAttribute("data-id", id);
    this.virtualInput.setAttribute("name", id);
    this.selectElement = select;
    this.fieldsElement.appendChild(select);
  }

  public get mapKeys(): string[] {
    return Object.keys(this.map);
  }

  public focus() {
    this.selectElement.focus();
  }
}