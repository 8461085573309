import { DOMTemplate } from "./denki";
import { FieldBox } from "./DigitalForm/FieldBox";
import { DigitalForm } from "./DigitalForm/DigitalForm";
import { FieldInputElement } from "./DigitalForm/Element/FieldInputElement";
import { FormTextElement } from "./DigitalForm/Element/FormTextElement";
import { FormRadioElement } from "./DigitalForm/Element/FormRadioElement";
import { FormSelectElement } from "./DigitalForm/Element/FormSelectElement";
import { FormNumberElement } from "./DigitalForm/Element/FormNumberElement";
import { FieldDomElement } from "./DigitalForm/FieldDomElement";
import { FieldCompositeElement } from "./DigitalForm/FieldCompositeElement"
import { FormElement } from "./DigitalForm/Element/FormElement";
import { FormTextAreaElement } from "./DigitalForm/Element/FormTextAreaElement";
import { FormCheckBoxElement } from "./DigitalForm/Element/FormCheckBoxElement";
import { FieldInputContainer, findFieldInput, ReadonlyFieldInputContainer } from "./DigitalForm/Element/FieldInputContainer";
import { FormZipCodeElement, ZipcodeReferenceType } from "./DigitalForm/Element/FormZipCodeElement";
import { FormPhoneNumberElement } from "./DigitalForm/Element/FormPhonenumberElement";
import { ElementCondition, FieldConditionCriteria, isFieldConditionAndCriteria } from "./DigitalForm/condition";
import { ElementInterface } from "./DigitalForm/definition/element/ElementInterface";
import { ElementDefinition } from "./DigitalForm/definition/element/ElementDefinition";
import { TextElementDefinition } from "./DigitalForm/definition/element/TextElementDefinition";
import { NumberElementDefinition } from "./DigitalForm/definition/element/NumberElementDefinition";
import { Validator } from "./DigitalForm/definition/Validator";
import { RadioElementDefinition } from "./DigitalForm/definition/element/RadioElementDefinition";
import { CheckBoxElementDefinition } from "./DigitalForm/definition/element/CheckBoxElementDefinition";
import { SelectElementDefinition } from "./DigitalForm/definition/element/SelectElementDefinition";
import { TextAreaElementDefinition } from "./DigitalForm/definition/element/TextAreaElementDefinition";
import { ZipCodeElementDefinition } from "./DigitalForm/definition/element/ZipCodeElementDefinition";
import { PhoneNumberElementDefinition } from "./DigitalForm/definition/element/PhoneNumberElementDefinition";
import { FieldDomTemplate } from "./DigitalForm/definition/element/FieldDomTemplate";
import { Persona } from "./DigitalForm/persona/Persona";
import { DigitalFormDefinition } from "./DigitalForm/definition/form/DigitalFormDefinition";
import { DigitalFormBoxDefinition } from "./DigitalForm/definition/form/DigitalFormBoxDefinition";
import { FieldObjectConcreteDefinition } from "./DigitalForm/definition/element/FieldObjectConcreteDefinition";
import { TemplateManager } from "./DigitalForm/template/TemplateManager";

function setupFormElement<T>(definition: ElementInterface, element: FormElement<T>) {
    if (definition.title) {
        element.title = definition.title;
    }
    if (definition.description) {
        element.description = definition.description;
    }
    if (definition.required) {
        element.required = definition.required;
    }
}

function setupPreSuffix(definition: TextElementDefinition | NumberElementDefinition, element: FormTextElement | FormNumberElement) {
    if (definition.prefix) {
        element.prefixElement.textContent = definition.prefix;
    }
    if (definition.suffix) {
        element.suffixElement.textContent = definition.suffix;
    }
}

const buildValidator = (validator: Validator): (valiue: any) => any[] => {
    if (validator === "StartWithJapanese") {
        return TemplateManager.Util.createValidatorStartWithJapaneseCharacter();
    }
    if (validator === "NumberOnly") {
        return TemplateManager.Util.createValidatorDigit();
    }
    if (validator === "KatakanaOnly") {
        return TemplateManager.Util.createValidatorKatakana();
    }
    if (validator === "ZenkakuOnly") {
        return TemplateManager.Util.createValidatorZenkaku();
    }
    if (validator.kind === "SpecifiedLengthDigitsOnly") {
        return TemplateManager.Util.createValidatorDigitPattern("", validator.pattern);
    }
    if (validator.kind === "SpecifiedLengthDigitsOnlyHankaku") {
        return TemplateManager.Util.createValidatorDigitPatternHankaku("", validator.pattern);
    }
    if(validator.kind === "SpecifiedLengthDigitsOnlyHankakuEisuji"){
        return TemplateManager.Util.createValidatorDigitPatternHankakuEisuji("",validator.pattern);
    }
    if(validator.kind === "LimitLengthOnlyZenkaku"){
        return TemplateManager.Util.createValidatorDigitLimitZenkaku("",validator.pattern);   
    }
    if(validator.kind === "LimitLengthOnlyZenkakuKatakana"){
        return TemplateManager.Util.createValidatorDigitLimitZenkakuKatakana("",validator.pattern);   
    }
};

function buildTextElement(definition: TextElementDefinition, template: DOMTemplate): FormTextElement {
    const formElement = new FormTextElement(definition.id, template);
    setupFormElement(definition, formElement);
    setupPreSuffix(definition, formElement);
    if (definition.min !== undefined) {
        formElement.textElement.minLength = definition.min;
    }
    if (definition.max !== undefined) {
        formElement.textElement.maxLength = definition.max;
    }
    if (definition.placeholder) {
        formElement.textElement.placeholder = definition.placeholder;
    }
    if (definition.validator) {
        formElement.validator = buildValidator(definition.validator);
    }
    if (definition.class) {
        formElement.fieldsElement.classList.add(definition.class);
    }
    if (definition.readonly) {
        formElement.textElement.readOnly = definition.readonly;
    }
    if (definition.initialValue) {
        formElement.value = definition.initialValue;
    }
    return formElement;
}

function buildRadioElement(definition: RadioElementDefinition, template: DOMTemplate): FormRadioElement {
    const element = new FormRadioElement(definition.id, definition.options, template);
    setupFormElement(definition, element);
    return element;
}

export function buildCheckBoxElement(definition: CheckBoxElementDefinition, template: DOMTemplate): FormCheckBoxElement {
    const element = new FormCheckBoxElement(definition.id, definition.options, template);
    setupFormElement(definition, element);
    return element;
}

function buildSelectElement(definition: SelectElementDefinition, template: DOMTemplate): FormSelectElement {
    const element = new FormSelectElement(definition.id, definition.options, template);
    setupFormElement(definition, element);
    return element;
}

function buildNumberElement(definition: NumberElementDefinition, template: DOMTemplate): FormNumberElement {
    const element = new FormNumberElement(definition.id, template);
    setupFormElement(definition, element);
    setupPreSuffix(definition, element);
    if (definition.min !== undefined) {
        element.min = definition.min;
    }
    if (definition.max !== undefined) {
        element.max = definition.max;
    }
    if (definition.initialValue) {
        element.initialValue = definition.initialValue;
    }
    return element;
}

function buildTextAreaElement(definition: TextAreaElementDefinition, template: DOMTemplate): FormTextAreaElement {
    const element = new FormTextAreaElement(definition.id, template);
    setupFormElement(definition, element);
    if (definition.placeholder) {
        element.textAreaElement.placeholder = definition.placeholder;
    }
    if (definition.maxLength) {
        element.textAreaElement.maxLength = definition.maxLength;
    }
    return element;
}

function buildZipCodeElement(definition: ZipCodeElementDefinition, template: DOMTemplate): FormZipCodeElement {
    const element = new FormZipCodeElement(definition.id, template);
    setupFormElement(definition, element);
    return element;
}

const buildPhoneNumberElement = (definition: PhoneNumberElementDefinition, template: DOMTemplate): FormPhoneNumberElement => {
    const element = new FormPhoneNumberElement(definition.id, template);
    setupFormElement(definition, element);
    return element;
};

export function buildFieldDomElement(definition: FieldDomTemplate, template: DOMTemplate): FieldDomElement {
    const element = new FieldDomElement(definition.id, definition.domTemplate, template);
    element.validator = definition.validate;
    element.calculateValue = definition.value;
    element.deserializeValue = definition.setValue;
    setupFormElement(definition, element);
    if (definition.manualUpdate) {
        definition.manualUpdate(element);
    }
    if (definition.setup) {
        definition.setup(element, definition);
        element.update();
    }
    if (definition.extraSetup) {
        definition.extraSetup(element, definition);
    }
    return element;
}

function buildFieldCompositeElement(definition: FieldObjectConcreteDefinition, rootContainer: FieldInputContainer, template: DOMTemplate): FieldCompositeElement {
    let id = definition.id;
    const index = definition.index;
    if (index !== undefined) {
        id = `${id}[${index}]`;
    }

    const element = new FieldCompositeElement(id, template);
    element.calculateValue = definition.value;
    setupFormElement(definition, element);
    const fields = definition.fields;
    for (let i = 0, l = fields.length; i < l; i++) {
        const field = fields[i];
        const subInputs = buildInput(field, rootContainer, element, template);
        subInputs.forEach(subInput => {
            element.appendFieldInput(subInput);
        });
    }
    if (definition.setup) {
        definition.setup(element, definition);
        element.update();
    }
    if (definition.extraSetup) {
        definition.extraSetup(element, definition);
    }
    return element;
}

export function createFieldInputElements(definition: ElementDefinition, rootContainer: FieldInputContainer, template: DOMTemplate): FieldInputElement {
    switch (definition.kind) {
        case "field":
            return buildFieldCompositeElement(definition, rootContainer, template);
        case "text":
            return buildTextElement(definition, template);
        case "radio":
            return buildRadioElement(definition, template);
        case "checkbox":
            return buildCheckBoxElement(definition, template);
        case "select":
            return buildSelectElement(definition, template);
        case "number":
            return buildNumberElement(definition, template);
        case "textarea":
            return buildTextAreaElement(definition, template);
        case "zipcode":
            return buildZipCodeElement(definition, template);
        case "phone_number":
            return buildPhoneNumberElement(definition, template);
        case "dom":
            return buildFieldDomElement(definition, template);
    }
    console.error(definition);
    throw new Error(`unknown kind: ${definition}`);
}

export const parseConditionCriteria = (condition: FieldConditionCriteria): { targetId: string, targetValue: string[] | number[]} => {
    if (typeof condition.value === "string") {
        return {targetId: condition.id, targetValue: [condition.value] };
    }
    return { targetId: condition.id, targetValue: condition.value };
}

export const parseCondition = (id: string, condition: ElementCondition): { targetAction: "disable" | "enable", targetPairs: { targetId: string, targetValue: string[] | number[]}[]} => {
    if (typeof condition === "string") {
        return { targetAction: "enable", targetPairs: [{ targetId: condition, targetValue: [id] }] };
    }
    const targetAction = condition.action || "enable" as const;
    if (isFieldConditionAndCriteria(condition)) {
        return { targetAction, targetPairs: condition.$and.map(c => parseConditionCriteria(c))}
    }
    return { targetAction, targetPairs: [ parseConditionCriteria(condition) ]};
}

const notsatisfiesCondition = (targetInput: FieldInputElement, targetValue: number[] | string[]): boolean => {
    const parsed = targetInput.value;
    if (targetInput instanceof FormCheckBoxElement) {
        const values: string[] = parsed;
        return (values ? values.every(value => !(targetValue as string[]).includes(value)) : true);
    } else if (targetInput instanceof FormRadioElement) {
        return (!(targetValue as string[]).includes(parsed));
    } else if (targetInput instanceof FormSelectElement) {
        return (!(targetValue as string[]).includes(parsed));
    } else if (targetInput instanceof FormNumberElement) {
        return (!(targetValue as number[]).includes(parsed));
    }
    throw new Error("unsupported condition");
}

const setupFormCondition = (fieldInput: FieldInputElement, condition: ElementCondition, rootContainer: ReadonlyFieldInputContainer, parentContainer: ReadonlyFieldInputContainer, shouldHideDisabledField:boolean = true) => {
    const { targetAction, targetPairs } = parseCondition(fieldInput.id, condition);
    const targetInputs = targetPairs.map(pair => findFieldInput(rootContainer, pair.targetId) || findFieldInput(parentContainer, pair.targetId));
    const callback = () => {
        const flag = targetAction === "enable";
        fieldInput.disabled = targetInputs.some((targetInput, i) => notsatisfiesCondition(targetInput, targetPairs[i].targetValue)) === flag;
        if (shouldHideDisabledField) {
            fieldInput.hidden = fieldInput.disabled;
        }
    };
    targetInputs.forEach(targetInput => { targetInput.virtualInput.addEventListener("change", callback); });
    callback();
}

const postProcessSetup = (fieldInput: FieldInputElement, data: ElementDefinition, rootContainer: ReadonlyFieldInputContainer, parentContainer: ReadonlyFieldInputContainer) => {
    if (data.condition) {
        setupFormCondition(fieldInput, data.condition, rootContainer, parentContainer, true);
    }
    if (data.refer) {
        const targetToken = data.refer.split("-");
        const targetId = targetToken[0];
        const targetInput = findFieldInput(rootContainer, targetId) || findFieldInput(parentContainer, targetId);
        if (targetInput instanceof FormZipCodeElement) {
            targetInput.addReferer(fieldInput, targetToken.length === 1 ? "raw" : <ZipcodeReferenceType>targetToken[1]);
        } else {
            throw new Error(`invalid reference: ${data.refer}`);
        }
    }
}

const postProcessSetupFieldCompositeElementCondition = (container: FieldCompositeElement, data: FieldObjectConcreteDefinition, form: DigitalForm) => {
    data.fields.forEach(definition => {
        const input = findFieldInput(container, definition.id);
        if (definition.kind === "field") {
            postProcessSetupFieldCompositeElementCondition(input as FieldCompositeElement, definition, form);
        }
        postProcessSetup(input, definition, form, container);
    });
}

const postProcessFieldBox = (container: FieldBox, data: DigitalFormBoxDefinition, form: DigitalForm) => {
    if (data.condition) {
        setupFormCondition(container, data.condition, form, form, false);
    }
    data.elements.forEach(definition => {
        const input = findFieldInput(container, definition.id);
        if (definition.kind === "field") {
            postProcessSetupFieldCompositeElementCondition(input as FieldCompositeElement, definition, form);
        }
        postProcessSetup(input, definition, form, container);
    });
}

const postProcessFormSetup = (form: DigitalForm, data: DigitalFormDefinition) => {
    data.boxes.forEach(definition => postProcessFieldBox(findFieldInput(form, definition.id) as FieldBox, definition, form));
}

export function buildInput(field: ElementDefinition, rootContainer: FieldInputContainer, parentContainer: FieldInputContainer, template: DOMTemplate): FieldInputElement[] {
    let min = 1;
    let max = 1;
    let variableNumber = false;
    if (field.cardinality) {
        if (typeof field.cardinality === "number") {
            min = field.cardinality;
            max = field.cardinality;
        } else if (typeof field.cardinality === "string") {
            const bind = field.cardinality;
            const fieldInput = findFieldInput(rootContainer, bind);
            const value = fieldInput.virtualInput.value;
            if (value && value !== "undefined") {
                min = JSON.parse(value);
            } else {
                min = 1;
            }

            max = min;
            const callback = () => {
                const inputs = buildInput(field, rootContainer, rootContainer, template);
                rootContainer.updateFieldInput(inputs[0], field.id);
                fieldInput.virtualInput.removeEventListener("change", callback);
            };
            fieldInput.virtualInput.addEventListener("change", callback);
            variableNumber = true;
        } else {
            if (field.cardinality.min) {
                min = field.cardinality.min;
            }
            if (field.cardinality.max) {
                max = field.cardinality.max;
            }
        }
    }
    const definition = (() => {
        if (!variableNumber) return field;
        const fieldDefinition: FieldObjectConcreteDefinition = {
            kind: "field",
            id: field.id,
            fields: []
        };
        for (let j = 0; j < min; j++) {
            const elementField = Object.assign({}, field);
            delete elementField.cardinality;
            elementField.index = j;
            fieldDefinition.fields.push(elementField);
        }
        return fieldDefinition;
    })();
    const fieldInput = createFieldInputElements(definition, rootContainer, template);
    if (field.hidden) {
        fieldInput.isGhostElement = true;
    }
    return [fieldInput];
}

export function appendFieldBox(form: DigitalForm, data: DigitalFormBoxDefinition, template: DOMTemplate) {
    const box = new FieldBox(data.id, template);
    if (data.title) {
        box.title = data.title;
    }
    if (data.required) {
        box.isRequired = data.required;
    }
    if (data.description) {
        box.description = data.description;
    }
    if (data.hidden) {
        box.hidden = data.hidden;
    }
    form.appendBox(box);
    data.elements.forEach(definition => buildInput(definition, form, form, template).forEach(subInput => box.appendFieldInput(subInput)));
}

export function buildDigitalForm(data: DigitalFormDefinition, template: DOMTemplate): DigitalForm {
    const form = new DigitalForm(data.id, template);
    if (data.title) {
        form.title = data.title;
    }
    data.boxes.forEach(definition => appendFieldBox(form, definition, template));
    if (form.numberOfBoxes > 0) {
        form.boxAtIndex(0).open();
    }
    postProcessFormSetup(form, data);
    return form;
}

export type FormElementPath = [DigitalForm, string];

const isNotNull = (data: any): boolean => data !== null;

const resolvePath = (path: FormElementPath): FieldInputElement => {
    if (!path[0]) return null;
    return findFieldInput(path[0], path[1]);
}

export interface SyncHandler {
    elements: FieldInputElement[];
    exec: (target: FieldInputElement) => void;
}

const createSyncHandler = (paths: FormElementPath[]): SyncHandler => {
    const elements = paths.filter(path => path[0]).map(resolvePath).filter(isNotNull);
    let syncing = false;
    const context = {
        elements,
        exec: (target: FieldInputElement) => {
            if (syncing || target.disabled) return;
            elements.filter(e => e !== target).forEach(e => {
                syncing = true;
                e.value = target.value;
                syncing = false;
            });
        }
    };
    return context;
}

export const conditionalSync = (conditionPath: FormElementPath, target: any, ...paths: FormElementPath[]) => {
    const handler = createSyncHandler(paths);
    const conditionField = resolvePath(conditionPath);
    const targetValues: any[] = (() => {
        if (typeof target === "string") return [target];
        return target as any[];
    })();
    if (!conditionField) return;
    conditionField.virtualInput.addEventListener("change", () => {
        if (targetValues.includes(conditionField.value)) {
            handler.exec(resolvePath(paths[0]));
        }
    });
    handler.elements.forEach(e => {
        e.virtualInput.addEventListener("change", () => {
            if (!targetValues.includes(conditionField.value)) {
                return;
            }
            handler.exec(e);
        });
    });
};

export const sync = (...paths: FormElementPath[]) => {
    const handler = createSyncHandler(paths);
    handler.elements.forEach(e => {
        e.virtualInput.addEventListener("change", () => {
            handler.exec(e);
        });
    });
    return handler;
};

const createAddressSyncHandler = (paths: FormElementPath[]): SyncHandler => {
    const elements = paths.filter(path => path[0]).map(resolvePath).filter(isNotNull);
    let syncing = false;
    const context = {
        elements,
        exec: (target: FieldInputElement) => {
            if (syncing) return;
            syncing = true;
            let total = "";
            if(elements[0].value) total += elements[0].value;
            if(elements[1].value) total += elements[1].value;
            if(elements[2].value) total += `　${elements[2].value}`;
            target.value = total;
            // target.value = `${elements[0].value}${elements[1].value}　${elements[2].value}`;
            syncing = false;
        }
    };
    return context;
}


export const addressSync = (
    targetPath: FormElementPath, 
    address: FormElementPath, 
    chouban: FormElementPath, 
    building: FormElementPath
) => {
    const handler = createAddressSyncHandler([address,chouban,building]);
    const targetField = resolvePath(targetPath);
    if (!targetField) return;
    handler.elements.forEach(e => {
        e.virtualInput.addEventListener("change", () => {
            handler.exec(targetField);
        });
    });
};

const createAddressSyncHandler2 = (paths: FormElementPath[]): SyncHandler => {
    const elements = paths.filter(path => path[0]).map(resolvePath).filter(isNotNull);
    let syncing = false;
    const context = {
        elements,
        exec: (target: FieldInputElement) => {
            if (syncing) return;
            syncing = true;
            let total = "";
            if(elements[0].value) total += elements[0].value;
            if(elements[1].value) total += elements[1].value;
            target.value = total;
            // target.value = `${elements[0].value}${elements[1].value}　${elements[2].value}`;
            syncing = false;
        }
    };
    return context;
}


export const addressSync2 = (
    targetPath: FormElementPath, 
    address: FormElementPath, 
    chouban: FormElementPath
) => {
    const handler = createAddressSyncHandler2([address,chouban]);
    const targetField = resolvePath(targetPath);
    if (!targetField) return;
    handler.elements.forEach(e => {
        e.virtualInput.addEventListener("change", () => {
            handler.exec(targetField);
        });
    });
};

export type FormMap = { [keys: string]: DigitalForm };
export type FormDefinitionMap = { [keys: string]: DigitalFormDefinition };

const hasRole = (definition: DigitalFormDefinition, role: string): boolean => {
    return getPersonaByRole(definition, role) !== undefined;
}

const getRoles = (definition: DigitalFormDefinition): string[] => {
    return definition.persona ? Object.keys(definition.persona) : [];
}

const getRolesFromMap = (map: FormDefinitionMap): string[] => {
    return [...new Set(Object.values(map).flatMap(definition => {
        return getRoles(definition);
    }))];
};

const getPersonaByRole = (definition: DigitalFormDefinition, role: string): Persona => {
    return definition.persona ? definition.persona[role] : undefined;
};

const getMutualPersonaProperties = (personaList: Persona[]): string[] => {
    return [...new Set(personaList.flatMap(persona => Object.keys(persona)))];
};

const getMutualPersonaPropertiesForRole = (map: FormDefinitionMap, role: string): string[] => {
    const personaList = Object.values(map).flatMap(definition => {
        const persona = getPersonaByRole(definition, role);
        return persona !== undefined ? [persona] : [];
    });
    return getMutualPersonaProperties(personaList);
};

const linkPersonaByRole = (formMap: FormMap, definitionMap: FormDefinitionMap, role: string) => {
    const formIds = Object.keys(formMap);
    getMutualPersonaPropertiesForRole(definitionMap, role).forEach(property => {
        const linkProperties = formIds.flatMap(id => {
            const form = formMap[id];
            const definition = definitionMap[id];
            if (!hasRole(definition, role)) {
                return [];
            }
            return [[form, definition.persona[role][property]] as FormElementPath];
        });
        sync(...linkProperties);
    });
};

export const linkPersona = (formMap: FormMap, definitionMap: FormDefinitionMap) => {
    const roles = getRolesFromMap(definitionMap);
    roles.forEach(role => {
        linkPersonaByRole(formMap, definitionMap, role);
    });
};