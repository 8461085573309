export interface FieldConditionLocalCriteria {
    id: string,
    value: string | string[] | number[]
}

export interface FieldSelector {
  formID: string;
  fieldID: string;
}

export interface FieldConditionGlobalCriteria {
    target: FieldSelector;
    value: string | string[] | number[];
}

export type FieldConditionCriteria = FieldConditionLocalCriteria | FieldConditionGlobalCriteria;

export interface FieldConditionAndCriteria {
    operator: "and",
    $and: FieldConditionCriteria[]
}

export type FieldCondition = FieldConditionCriteria | FieldConditionAndCriteria;

export const isFieldConditionAndCriteria = (condition: FieldCondition): condition is FieldConditionAndCriteria => {
    return (condition as FieldConditionAndCriteria).operator === "and";
}

export type FieldConditionObject = { action?: "disable"} & FieldCondition;

export type ElementCondition = string | FieldConditionObject;