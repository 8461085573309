import {
    sync, conditionalSync, FormElementPath, addressSync,addressSync2
} from '../../../src/digitalForm';

import moving from "../../yokosuka/formData/moving.json";
import residents_certificate from "../../yokosuka/formData/residents_certificate.json";
import seal_registration from "../../yokosuka/formData/seal_registration.json";
import seal_registration_request from "../../yokosuka/formData/seal_registration_request.json";
import mynumber from "../../yokosuka/formData/mynumber.json";
import digital_certificate from "../../yokosuka/formData/digital_certificate.json";
import childcare_allowance_certificate from "../../yokosuka/formData/childcare_allowance_certificate.json";
import pediatric_medical_certificate1 from "../../yokosuka/formData/pediatric_medical_certificate1.json";
import pediatric_medical_certificate2 from "../../yokosuka/formData/pediatric_medical_certificate2.json";

import { DigitalForm } from '../../../src/DigitalForm/DigitalForm';
import { findFieldInput } from '../../../src/DigitalForm/Element/FieldInputContainer';
import { Notifier } from '../../../src/main';

import localize_table from "../../yokosuka/formData/localize_idou.json";
import { DigitalFormTemplate } from '../../../src/DigitalForm/template/DigitalFormTemplate';
import { DigitalApplicationDefinition } from '../../../src/DigitalForm/definition/form/DigitalApplicationDefinition';
import { resolveDigitalFormDefinition } from '../../../src/DigitalForm/template/resolver';
import { formTemplates } from "../../yokosuka/formData/templates";
import { TemplateManager } from "../../../src/DigitalForm/template/TemplateManager";

Object.entries(formTemplates).forEach(entry => {
    TemplateManager.addTemplate(entry[0], entry[1]);
});

export const FormDefinition: DigitalApplicationDefinition = {
    forms: [
        <DigitalFormTemplate>moving,
        <DigitalFormTemplate>residents_certificate,
        <DigitalFormTemplate>seal_registration,
        <DigitalFormTemplate>seal_registration_request,
        <DigitalFormTemplate>mynumber,
        <DigitalFormTemplate>digital_certificate,
        <DigitalFormTemplate>pediatric_medical_certificate1,
        <DigitalFormTemplate>childcare_allowance_certificate,
        // <DigitalFormDefinition>pediatric_medical_certificate2
    ].map(template => resolveDigitalFormDefinition(template)),
    extraSetup: (formMap: { [keys: string]: DigitalForm }, definitionMap: { [keys: string]: DigitalFormDefinition }) => {
        const { 
            moving,
            residents_certificate,
            seal_registration,
            seal_registration_request,
            mynumber,
            digital_certificate,
            childcare_allowance_certificate,
            pediatric_medical_certificate1,
            // pediatric_medical_certificate2
         } = formMap;

        //職員側環境しか表示しない
        //  Object.keys(definitionMap).forEach(id => {
        //     const form = formMap[id];
        //     definitionMap[id].boxes.forEach(boxDefinition => {
        //         if (!boxDefinition.admin) {
        //             const box = findFieldInput(form, boxDefinition.id);
        //             box.hidden = true;
        //         }
        //     });
        // });

         //職員側環境を隠す
        //boxにadminプロパティをもっていたらhiddenにしてあげるだけ。trueかどうかは見ていない
        Object.keys(definitionMap).forEach(id => {
            const form = formMap[id];
            definitionMap[id].boxes.forEach(boxDefinition => {
                if (boxDefinition.admin) {
                    const box = findFieldInput(form, boxDefinition.id);
                    box.disabled = true;
                    box.hidden = true;
                }
            });
        });

        

        //ローカライズ初期化
        if(moving){
        let localized_doms=[];

         function initLocalize(){
             Object.keys(localize_table).forEach( key => {
                 localize_table[key].forEach(elem => {
                     const form = moving;
                     // console.log(elem.id);
                     const target_field = findFieldInput(form, elem.id);
                     const target_dom = target_field.element.querySelector(`[data-role=${elem.role}]`);
                     elem.dom = target_dom;
                     localized_doms.push(target_dom);
                     if(!target_dom.querySelector(".condition-origin")){
                         const alt_node = document.createElement("span");
                         alt_node.classList.add("condition-origin","show");
                         alt_node.textContent = target_dom.textContent;
                         target_dom.textContent ="";
                         target_dom.appendChild(alt_node);
                     }
                     const condition_node = document.createElement("span");
                     condition_node.textContent = elem.text;
                     condition_node.classList.add(`condition-${key}`);
                     target_dom.appendChild(condition_node);
                 });
             });
         }
         initLocalize();

        
        //無理やり値をセットする
        function forceSetValue(form,id,value){
            const target = findFieldInput(form, id);
            console.log(target);
            target.value = value;
        }

        //プロパティのhiddenを使うと色々影響してくるのでcssで消す
        //display:noneは既に使われていたので
        function setForceHidden(form,id,needDisabaled){
            const target = findFieldInput(form, id);
            target.element.classList.add("force-hidden");
            if(needDisabaled) target.disabled = true;
        }
        function removeForceHidden(form,id,needDisabaled){
            //hiddenを使えない時用
            const target = findFieldInput(form, id);
            // target.element.removeAttribute("style");
            target.element.classList.remove("force-hidden");
            if(needDisabaled) target.disabled = false;
        }

        //手続きの種類によって書き換える
        const procedure_type = findFieldInput(moving, "procedure_type");
        procedure_type.virtualInput.addEventListener("change",()=>{
            console.log(procedure_type.value);
            const no_need_honseki_and_foreginer = ["2","3","4","9","10","11","12","14","15"];
            if(procedure_type.value === "3"){
                for (let i = 1; i <= 5; i++) {
                    setForceHidden(moving,`mover_honseki_${i}`,true);
                }
                for (let i = 1; i <= 5; i++) {
                    setForceHidden(moving,`nationality_${i}`,true);
                    setForceHidden(moving,`qualification_${i}`,true);
                    setForceHidden(moving,`mover_stay_${i}`,true);
                    setForceHidden(moving,`residence_type_${i}`,true);
                    setForceHidden(moving,`mover_card_${i}`,true);
                    setForceHidden(moving,`period_${i}`,true);
                }
            } else if(no_need_honseki_and_foreginer.includes(procedure_type.value)){
                // mover_honseki_1
                // foreigner_field_1
                for (let i = 1; i <= 5; i++) {
                    setForceHidden(moving,`mover_honseki_${i}`,true);
                    setForceHidden(moving,`foreigner_field_${i}`,true);
                }
            } else {
                for (let i = 1; i <= 5; i++) {
                    removeForceHidden(moving,`mover_honseki_${i}`,true);
                    removeForceHidden(moving,`foreigner_field_${i}`,true);
                }
            }

            //外国籍をお持ちですか質問を消しつつ強制的に質問に答えておく
            //ただし、外国籍かどうかの質問は勝手に答えちゃうので戻らない
            if(procedure_type.value === "5" || procedure_type.value === "8"){                
                for (let i = 1; i <= 5; i++) {
                    forceSetValue(moving,`is_foreigner_${i}`,"yes");
                    setForceHidden(moving,`is_foreigner_${i}`,false);
                }
            } else if(procedure_type.value === "6"){
                for (let i = 1; i <= 5; i++) {
                    forceSetValue(moving,`is_foreigner_${i}`,"no");
                    setForceHidden(moving,`is_foreigner_${i}`,false);
                }
            } else {
                for (let i = 1; i <= 5; i++) {
                    removeForceHidden(moving,`is_foreigner_${i}`,false);
                }
            }

            //ローカライズしたdomの初期化
            //うーんここもうちょっと負荷下げられるような
            localized_doms.forEach( dom =>{
                for (let i = 0; i < dom.children.length; i++) {
                    dom.children[i].classList.remove("show");                    
                }
                dom.querySelector(".condition-origin").classList.add("show");
            });
            //ローカライズ
            const keys = Object.keys(localize_table);
            if(keys.includes(procedure_type.value)){
                localize_table[procedure_type.value].forEach(elem => {
                    elem.dom.querySelector(".condition-origin").classList.remove("show");
                    elem.dom.querySelector(`.condition-${procedure_type.value}`).classList.add("show");
                });
            }

            //initial valueがうまく動いてくれない感があるのでガッツで書き換える
            const about_live_1 = findFieldInput(moving, "about_live_1");
            const about_live_2 = findFieldInput(moving, "about_live_2");
            const about_live_3 = findFieldInput(moving, "about_live_3");
            const bikou_1 = findFieldInput(moving, "bikou_1");
            const bikou_2 = findFieldInput(moving, "bikou_2");
            const bikou_3 = findFieldInput(moving, "bikou_3");
            const bikou_4 = findFieldInput(moving, "bikou_4");
            const bikou_5 = findFieldInput(moving, "bikou_5");
            about_live_1.value = 
            about_live_2.value = 
            about_live_3.value = 
            bikou_1.value = 
            bikou_2.value = 
            bikou_3.value = 
            bikou_4.value = 
            bikou_5.value = "";
            if(procedure_type.value === "16"){
                about_live_1.value = "転居前の住所に住んでいた期間";
                about_live_2.value = "から";
                about_live_3.value = "まで";
            }
            else if(procedure_type.value === "11"){
                bikou_1.value = "生計が別になった";
                bikou_5.value = "届出人氏名";
            } 
            else if(procedure_type.value === "14"){
                bikou_1.value = "・他市への転入手続きはしておりません。";
                bikou_2.value = "・貴市へ迷惑はかけません。";
                bikou_5.value = "届出人氏名";
            } 
            else if(procedure_type.value === "15"){
                bikou_1.value = "・転出証明書を紛失しました。";
                bikou_2.value = "・他市への転入手続きはしておりません。";
                bikou_3.value = "・転出証明書が出てきた場合は返却します。";
                bikou_4.value = "・貴市へ迷惑はかけません。";
                bikou_5.value = "届出人氏名";
            }
        });

        //1. 異動届
        //引越しをされる方(異動者)の情報
        //異動者の先年月日
        //あとで全員にforする
        
        for (let i = 1; i <= 5; i++) {
            conditionalSync([moving,`is_foreigner_${i}`],"yes",
                [moving,`mover_foreigner_birthdate_${i}`],
                [moving,`mover_birthdate_${i}`]
            );
            conditionalSync([moving,`is_foreigner_${i}`],"no",
                [moving,`mover_national_birthdate_${i}`],
                [moving,`mover_birthdate_${i}`]
            );
        }

        for (let i = 1; i <= 5; i++) {
            const is_foreigner = findFieldInput(moving,`is_foreigner_${i}`);
            is_foreigner.virtualInput.addEventListener("change",()=>{
                const field_title = findFieldInput(moving,`mover_old_name_field_${i}`);
                const field_title_dom = field_title.element.querySelector(`[data-role=title]`);
                const input_title = findFieldInput(moving,`mover_old_name_${i}`);
                const input_title_dom = input_title.element.querySelector(`[data-role=title]`);
                const furigana_title = findFieldInput(moving,`mover_old_kana_${i}`);
                const name_placeholder = input_title.element.querySelector(`fieldset > input`);
                const kana_placeholder = furigana_title.element.querySelector("fieldset > input");

                if(is_foreigner.value === "no"){
                    field_title_dom.textContent = "旧氏登録している方は入力してください。";
                    input_title_dom.textContent = "旧氏";
                    name_placeholder.setAttribute("placeholder","例：横須賀");
                    kana_placeholder.setAttribute("placeholder","例：ヨコスカ");
                } else {
                    field_title_dom.textContent = "通称名がある方は入力してください。";
                    input_title_dom.textContent = "通称名";
                    name_placeholder.setAttribute("placeholder","例：横須賀　タロウ");
                    kana_placeholder.setAttribute("placeholder","例：ヨコスカ　タロウ");
                }
            });
        }

        //住所設定時
        //住所設定前の住所は届出者で入力した代理人の住所と同じですか。
        //代理人住所が国外のパターンに対応していない。
        // const same_as_agent_address:FormElementPath = [moving,"same_as_agent_address"];
        // conditionalSync(same_as_agent_address,"yes",
        // [moving,"target_agent_address"],
        // [moving,"ghost_agent_setting_address"]);
        // conditionalSync(same_as_agent_address,"no",
        // [moving,"target_old_setting_address"],
        // [moving,"ghost_agent_setting_address"]);

        
        //本籍

        //筆頭者No1
        conditionalSync([moving,"is_honseki_leader_1"],"refer:mover_name_1",
                [moving,"mover_name_1"],
                [moving,"ghost_honseki_name_1"]
        );
        conditionalSync([moving,"is_honseki_leader_1"],"refer:is_honseki_name_1",
                [moving,"is_honseki_name_1"],
                [moving,"ghost_honseki_name_1"]
        );

        //筆頭者No2
        conditionalSync([moving,"is_honseki_leader_2"],"refer:mover_name_2",
                [moving,"mover_name_2"],
                [moving,"ghost_honseki_name_2"]
        );

        conditionalSync([moving,"is_honseki_leader_same_2"],"yes",
                [moving,"ghost_honseki_name_1"],
                [moving,"ghost_honseki_name_2"]
        );
        conditionalSync([moving,"is_honseki_leader_same_2"],"no",
                [moving,"is_honseki_name_2"],
                [moving,"ghost_honseki_name_2"]
        );
        //本籍地No2
        conditionalSync([moving,"is_honsekichi_same_2"],"refer:target_mover_honseki_address_1",
            [moving,"target_mover_honseki_address_1"],
            [moving,"ghost_honseki_address_2"]
        );
        conditionalSync([moving,"is_honsekichi_same_2"],"refer:target_mover_honseki_address_2",
            [moving,"target_mover_honseki_address_2"],
            [moving,"ghost_honseki_address_2"]
        );


        //筆頭者No3
        conditionalSync([moving,"is_honseki_leader_3"],"refer:mover_name_3",
            [moving,"mover_name_3"],
            [moving,"ghost_honseki_name_3"]
        );
        conditionalSync([moving,"is_honseki_leader_same_3"],"1",
            [moving,"ghost_honseki_name_1"],
            [moving,"ghost_honseki_name_3"]
        );
        conditionalSync([moving,"is_honseki_leader_same_3"],"2",
            [moving,"ghost_honseki_name_2"],
            [moving,"ghost_honseki_name_3"]
        );
        conditionalSync([moving,"is_honseki_leader_same_3"],"3",
            [moving,"is_honseki_name_3"],
            [moving,"ghost_honseki_name_3"]
        );
        //本籍地No.3
        conditionalSync([moving,"is_honsekichi_same_3"],"1",
            [moving,"target_mover_honseki_address_1"],
            [moving,"ghost_honseki_address_3"]
        );
        conditionalSync([moving,"is_honsekichi_same_3"],"2",
            [moving,"ghost_honseki_address_2"],
            [moving,"ghost_honseki_address_3"]
        );
        conditionalSync([moving,"is_honsekichi_same_3"],"3",
            [moving,"target_mover_honseki_address_3"],
            [moving,"ghost_honseki_address_3"]
        );

        //筆頭者No4
        conditionalSync([moving,"is_honseki_leader_4"],"refer:mover_name_4",
            [moving,"mover_name_4"],
            [moving,"ghost_honseki_name_4"]
        );
        conditionalSync([moving,"is_honseki_leader_same_4"],"1",
            [moving,"ghost_honseki_name_1"],
            [moving,"ghost_honseki_name_4"]
        );
        conditionalSync([moving,"is_honseki_leader_same_4"],"2",
            [moving,"ghost_honseki_name_2"],
            [moving,"ghost_honseki_name_4"]
        );
        conditionalSync([moving,"is_honseki_leader_same_4"],"3",
            [moving,"ghost_honseki_name_3"],
            [moving,"ghost_honseki_name_4"]
        );
        conditionalSync([moving,"is_honseki_leader_same_4"],"4",
            [moving,"is_honseki_name_4"],
            [moving,"ghost_honseki_name_4"]
        );
        //本籍地No.4
        conditionalSync([moving,"is_honsekichi_same_4"],"1",
            [moving,"target_mover_honseki_address_1"],
            [moving,"ghost_honseki_address_4"]
        );
        conditionalSync([moving,"is_honsekichi_same_4"],"2",
            [moving,"ghost_honseki_address_2"],
            [moving,"ghost_honseki_address_4"]
        );
        conditionalSync([moving,"is_honsekichi_same_4"],"3",
            [moving,"ghost_honseki_address_3"],
            [moving,"ghost_honseki_address_4"]
        );
        conditionalSync([moving,"is_honsekichi_same_4"],"4",
            [moving,"target_mover_honseki_address_4"],
            [moving,"ghost_honseki_address_4"]
        );

        //筆頭者No5
        conditionalSync([moving,"is_honseki_leader_5"],"refer:mover_name_5",
            [moving,"mover_name_5"],
            [moving,"ghost_honseki_name_5"]
        );
        conditionalSync([moving,"is_honseki_leader_same_5"],"1",
            [moving,"ghost_honseki_name_1"],
            [moving,"ghost_honseki_name_5"]
        );
        conditionalSync([moving,"is_honseki_leader_same_5"],"2",
            [moving,"ghost_honseki_name_2"],
            [moving,"ghost_honseki_name_5"]
        );
        conditionalSync([moving,"is_honseki_leader_same_5"],"3",
            [moving,"ghost_honseki_name_3"],
            [moving,"ghost_honseki_name_5"]
        );
        conditionalSync([moving,"is_honseki_leader_same_5"],"4",
            [moving,"ghost_honseki_name_4"],
            [moving,"ghost_honseki_name_5"]
        );
        conditionalSync([moving,"is_honseki_leader_same_5"],"5",
            [moving,"is_honseki_name_5"],
            [moving,"ghost_honseki_name_5"]
        );
        //本籍地No.5
        conditionalSync([moving,"is_honsekichi_same_5"],"1",
            [moving,"target_mover_honseki_address_1"],
            [moving,"ghost_honseki_address_5"]
        );
        conditionalSync([moving,"is_honsekichi_same_5"],"2",
            [moving,"ghost_honseki_address_2"],
            [moving,"ghost_honseki_address_5"]
        );
        conditionalSync([moving,"is_honsekichi_same_5"],"3",
            [moving,"ghost_honseki_address_3"],
            [moving,"ghost_honseki_address_5"]
        );
        conditionalSync([moving,"is_honsekichi_same_5"],"4",
            [moving,"ghost_honseki_address_4"],
            [moving,"ghost_honseki_address_5"]
        );
        conditionalSync([moving,"is_honsekichi_same_5"],"5",
            [moving,"target_mover_honseki_address_5"],
            [moving,"ghost_honseki_address_5"]
        );

        //これなんかよくわからんが、備考欄のやつ最初に値セットを2回しないと変に出現するので。
        //とはいえ結局かちかちしまくると出現してしまうな。あとで手をうつ
        procedure_type.value = "1";
        procedure_type.value = "1";
        }

        //住所の同期        
        function addressSyncer(form,target){
            target.forEach( address =>{
                addressSync([form,`target_${address}`],
                    [form, address],
                    [form, `${address}_chouban`],
                    [form, `${address}_building`]
                );
            });
        }
        //本籍の同期
        function addressSyncer2(form,target){
            target.forEach( address =>{
                addressSync2([form,`target_${address}`],
                    [form, address],
                    [form, `${address}_chouban`]
                );
            });
        }
        //1異動届
        if(moving){
        addressSyncer(moving,[
            "agent_address",
            "agent_different_address",
            "agent_different_address2",
            "ghost_premoved_address",
            "ghost_postmoved_address",
            "new_address",
            "old_address",
            "old_setting_address",
            "no_report_address"
        ]);
        addressSyncer2(moving,[
            "mover_honseki_address_1",
            "mover_honseki_address_2",
            "mover_honseki_address_3",
            "mover_honseki_address_4",
            "mover_honseki_address_5"
        ]);
        }
        // 2住民票請求書
        if(residents_certificate){
        addressSyncer(residents_certificate,[
            "listed_address",
            "user_address",
            "applicant_address"
        ]);
        }
        //3、印鑑登録申請書
        if(seal_registration){
        addressSyncer(seal_registration,[
            "register_address",
            "applicant_address",
            "guarantor_address"
        ]);
        }
        //4、印鑑証明交付申請書
        if(seal_registration_request){
        addressSyncer(seal_registration_request,[
            "register_address",
            "applicant_address"
        ]);
        }
        // 5個人番号
        if(mynumber){
        addressSyncer(mynumber,[
            "applicant_address",
            "before_address",
            "agent_address"
        ]);
        }

        // 6電子証明関係届出書
        if(digital_certificate){
        addressSyncer(digital_certificate,[
            "applicant_address",
            "agent_address"
        ]);
        }
        //7児童手当
        if(childcare_allowance_certificate){
        addressSyncer(childcare_allowance_certificate,[
            "former_beneficialy_address",
            "applicant_address",
            "address_on_Jan_1",
            "partner_address",
            "partner_address_on_Jan_1"
        ]);
        conditionalSync([childcare_allowance_certificate,"same_partner_january_address"],"yes",
            [childcare_allowance_certificate,"target_address_on_Jan_1"],
            [childcare_allowance_certificate,"target_partner_address_on_Jan_1_bind"]
        );
        conditionalSync([childcare_allowance_certificate,"same_partner_january_address"],["no", null],
            [childcare_allowance_certificate,"target_partner_address_on_Jan_1"],
            [childcare_allowance_certificate,"target_partner_address_on_Jan_1_bind"]
        );
        }
        //8小児医療
        if(pediatric_medical_certificate1){
        addressSyncer(pediatric_medical_certificate1,[
            "father_address",
            "mother_address",
            "child_address_1",
            "child_address_2",
            "child_address_3"
        ]);
        }

        // moving,
        //     residents_certificate,
        //     seal_registration,
        //     seal_registration_request,
        //     mynumber,
        //     digital_certificate,
        //     childcare_allowance_certificate,
        //     pediatric_medical_certificate1,

        //同期

        //提出日
        // sync(
        //     [moving, "notification_date"],
        //     [residents_certificate, "notification_date"],
        //     [seal_registration, "notification_date"],
        //     [seal_registration_request,"notification_date"],
        //     [mynumber,"notification_date"],
        //     [digital_certificate,"notification_date"],
        //     [childcare_allowance_certificate,"notification_date"],
        //     [pediatric_medical_certificate1,"notification_date"]
        // );
        // ■２：【1.異動届】の「7.引っ越し先の世帯情報」の「引っ越し先の住所」（帳票では「新しい住所」）を下記へ反映
        //【1.異動届】の「7.引っ越し先の世帯情報」の「引っ越し先の住所」（帳票では「新しい住所」）を【5.個人番号カード】の「3.申請者の情報」の住所にも反映してください。
        //郵便番号
        // sync(
        //     [moving,"new_zipcode"],
        //     [seal_registration,"register_zipcode"],
        //     [seal_registration_request,"register_zipcode"],
        //     [digital_certificate,"applicant_zipcode"],
        //     [childcare_allowance_certificate,"applicant_zipcode"],
        //     [pediatric_medical_certificate1,"father_zipcode"],
        //     [mynumber,"applicant_zipcode"]
        // );
        //都道府県・市区町村・町名
        // sync(
        //     [moving,"new_address"],
        //     [seal_registration,"register_address"],
        //     [seal_registration_request,"register_address"],
        //     [digital_certificate,"applicant_address"],
        //     [childcare_allowance_certificate,"applicant_address"],
        //     [pediatric_medical_certificate1,"father_address"],
        //     [mynumber,"applicant_address"]
        // );
        //字名丁目・番地・号など
        // sync(
        //     [moving,"new_address_chouban"],
        //     [seal_registration,"register_address_chouban"],
        //     [seal_registration_request,"register_address_chouban"],
        //     [digital_certificate,"applicant_address_chouban"],
        //     [childcare_allowance_certificate,"applicant_address_chouban"],
        //     [pediatric_medical_certificate1,"father_address_chouban"],
        //     [mynumber,"applicant_address_chouban"]
        // );
        //建物名（方書）
        // sync(
        //     [moving,"new_address_building"],
        //     [seal_registration,"register_address_building"],
        //     [seal_registration_request,"register_address_building"],
        //     [digital_certificate,"applicant_address_building"],
        //     [childcare_allowance_certificate,"applicant_address_building"],
        //     [pediatric_medical_certificate1,"father_address_building"],
        //     [mynumber,"applicant_address_building"]
        // );

        //がっちゃんこ
        // sync(
        //     [moving,"target_new_address"],
        //     [seal_registration,"target_register_address"],
        //     [seal_registration_request,"target_register_address"],
        //     [digital_certificate,"target_applicant_address"],
        //     [childcare_allowance_certificate,"target_applicant_address"],
        //     [pediatric_medical_certificate1,"target_father_address"]
        // );

        //■３：異動届の【4.届出人の情報】で届出人が「代理人」を選択して入力した代理人の住所を下記へ反映
        //郵便番号
        // sync(
        //     [moving,"agent_zipcode"],
        //     [residents_certificate,"applicant_zipcode"],
        //     [seal_registration,"applicant_zipcode"],
        //     [seal_registration_request,"applicant_zipcode"],
        //     [mynumber,"agent_zipcode"],
        //     [digital_certificate,"agent_zipcode"]
        // );
        //都道府県・市区町村・町名
        // sync(
        //     [moving,"agent_address"],
        //     [residents_certificate,"applicant_address"],
        //     [seal_registration,"applicant_address"],
        //     [seal_registration_request,"applicant_address"],
        //     [mynumber,"agent_address"],
        //     [digital_certificate,"agent_address"]
        // );
        //字名丁目・番地・号など
        // sync(
        //     [moving,"agent_address_chouban"],
        //     [residents_certificate,"applicant_address_chouban"],
        //     [seal_registration,"applicant_address_chouban"],
        //     [seal_registration_request,"applicant_address_chouban"],
        //     [mynumber,"agent_address_chouban"],
        //     [digital_certificate,"agent_address_chouban"]
        // );
        //建物名（方書）
        // sync(
        //     [moving,"agent_address_building"],
        //     [residents_certificate,"applicant_address_building"],
        //     [seal_registration,"applicant_address_building"],
        //     [seal_registration_request,"applicant_address_building"],
        //     [mynumber,"agent_address_building"],
        //     [digital_certificate,"agent_address_building"]
        // );
        //がっちゃんこ
        // sync(
        //     [moving,"target_agent_address"],
        //     [residents_certificate,"target_applicant_address"],
        //     [seal_registration,"target_guarantor_address"],
        //     [seal_registration_request,"target_applicant_address"],
        //     [mynumber,"target_agent_address"]
        // );

        // ■４：異動届の「4.届出人の情報」の「電話番号」を下記へ反映
        // sync(
        //     [moving,"applicant_phone"],
        //     [residents_certificate,"applicant_phone"]
        // );
        // ■５：【7.小児医療交付申請書】の「3.申請者の情報」の名前を下記へ反映
        // sync(
        //     [childcare_allowance_certificate,"applicant_name"],
        //     [pediatric_medical_certificate1,"father_name"]
        // );
        //■６：【7.小児医療交付申請書】の「3.申請者の情報」のフリガナを下記へ反映
        // sync(
        //     [childcare_allowance_certificate,"applicant_kana"],
        //     [pediatric_medical_certificate1,"father_kana"]
        // );
        // ■７：【7.小児医療交付申請書】の「3.申請者の情報」の生年月日を下記へ反映
        // sync(
        //     [childcare_allowance_certificate,"birthdate"],
        //     [pediatric_medical_certificate1,"father_birthdate"]
        // );
        //■８：【7.小児医療交付申請書】の「4.配偶者の情報」の名前を下記へ反映
        // sync(
        //     [childcare_allowance_certificate,"partner_name"],
        //     [pediatric_medical_certificate1,"mother_name"]
        // );
        //■９：【7.小児医療交付申請書】の「4.配偶者の情報」のフリガナを下記へ反映
        // sync(
        //     [childcare_allowance_certificate,"partner_kana"],
        //     [pediatric_medical_certificate1,"mother_kana"]
        // );
        //■１０：【7.小児医療交付申請書】の「4.配偶者の情報」の生年月日を下記へ反映
        // sync(
        //     [childcare_allowance_certificate,"partner_birthdate"],
        //     [pediatric_medical_certificate1,"mother_birthdate"]
        // );
        //■１１：【7.小児医療交付申請書】の「4.配偶者の情報」の電話番号を下記へ反映
        // sync(
        //     [childcare_allowance_certificate,"partner_phone"],
        //     [pediatric_medical_certificate1,"mother_phone"]
        // );
        //■１２：【7.小児医療交付申請書】の「5.お子さんの情報」の名前を下記へ反映
        // sync(
        //     [childcare_allowance_certificate,"child_name_1"],
        //     [pediatric_medical_certificate1,"child_name_1"]
        // );
        // sync(
        //     [childcare_allowance_certificate,"child_name_2"],
        //     [pediatric_medical_certificate1,"child_name_2"]
        // );
        // sync(
        //     [childcare_allowance_certificate,"child_name_3"],
        //     [pediatric_medical_certificate1,"child_name_3"]
        // );
        //■１３：【7.小児医療交付申請書】の「5.お子さんの情報」のフリガナを下記へ反映
        // sync(
        //     [childcare_allowance_certificate,"child_kana_1"],
        //     [pediatric_medical_certificate1,"child_kana_1"]
        // );
        // sync(
        //     [childcare_allowance_certificate,"child_kana_2"],
        //     [pediatric_medical_certificate1,"child_kana_2"]
        // );
        // sync(
        //     [childcare_allowance_certificate,"child_kana_3"],
        //     [pediatric_medical_certificate1,"child_kana_3"]
        // );
        //■１４：【7.小児医療交付申請書】の「5.お子さんの情報」の生年月日を下記へ反映
        // sync(
        //     [childcare_allowance_certificate,"child_birthdate_1"],
        //     [pediatric_medical_certificate1,"child_birthdate_1"]
        // );
        // sync(
        //     [childcare_allowance_certificate,"child_birthdate_2"],
        //     [pediatric_medical_certificate1,"child_birthdate_2"]
        // );
        // sync(
        //     [childcare_allowance_certificate,"child_birthdate_3"],
        //     [pediatric_medical_certificate1,"child_birthdate_3"]
        // );


        if(mynumber){
            const type_of_procedure = findFieldInput(mynumber,"type_of_procedure");
            const parent = type_of_procedure.element.parentNode;
            const alert = document.createElement('p');
            alert.classList.add("injection-alert");
            alert.innerHTML = `
            ※住民基本台帳用の暗証番号<br>お引越しの手続きに伴い必要となる、個人番号カードの住所・氏名等の変更手続きの際などに使用する暗証番号です。<br><br>※券面事項入力補助用の暗証番号<br>個人番号カードに登録されている、個人番号や基本4情報（住所、氏名、生年月日、性別）を確認・利用する際に使用する暗証番号です。
            `;
            parent.insertBefore(alert, type_of_procedure.element.nextSibling);
        }

    },
    extraPages: [

    ],
    onFinishPreview: () => {
    }
};
