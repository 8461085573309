import { FormElement } from "./FormElement";
import { DOMTemplate } from "../../denki";
import { node } from "../Util";
import { FieldInputElement } from "./FieldInputElement";

export class FormTextElement extends FormElement implements FieldInputElement {
  public readonly fieldsElement: HTMLElement;
  public readonly prefixElement: HTMLLabelElement;
  public readonly suffixElement: HTMLLabelElement;
  public readonly textElement: HTMLInputElement;
  public validator?: (text: string) => string[];

  constructor(id: string, template: DOMTemplate) {
    super(id, template.get("field-element"));
    this.fieldsElement = this.element.querySelector("[data-role=elements]");
    const fieldset = node("fieldset");
    this.prefixElement = node("label", {}, "");
    this.suffixElement = node("label", {}, "");
    this.textElement = node("input", {
      type: 'text',
      name: id,
      'data-name': name
    });
    this.virtualInput.setAttribute("data-id", id);
    this.virtualInput.setAttribute("name", id);
    this.virtualInput.value = "";
    this.virtualInput.addEventListener("change", () => {
      const value = this.value;
      if (this.textElement.value !== value) {
        this.textElement.value = value;
      }
    });

    var isComposing = false;

    this.textElement.addEventListener('compositionstart', event => {
      isComposing = true;
      // console.log('composition start');
    });
    this.textElement.addEventListener('compositionend', event => {
      this.updateValue();
      isComposing = false;
      // console.log('composition end');
    });
    this.textElement.addEventListener('input', event => {
      if (!isComposing) {
        this.updateValue();
      }
    });

    //keyupけずった
    ["paste", "cut", "change"].forEach(event => {
      this.textElement.addEventListener(event, e => {
        this.updateValue();
      });
    });
    fieldset.appendChild(this.prefixElement);
    fieldset.appendChild(this.textElement);
    fieldset.appendChild(this.suffixElement);
    this.fieldsElement.appendChild(fieldset);
  }

  get value(): string {
    try {
      return (this.disabled) ? JSON.parse("") : JSON.parse(this.virtualInput.value);
    } catch (e) {
    }
    return null;
  }

  set value(value: string) {
    if (value) {
      this.virtualInput.value = JSON.stringify(value);
    } else {
      this.virtualInput.value = `""`;
    }
    this.updateValidation();
    this.dispatchChangeEvent();
  }

  public focus() {
    this.textElement.focus();
  }

  private updateValue() {
    setTimeout(() => {
      this.value = this.textElement.value;
    }, 0);
  }

  get validationErrors(): string[] {
    const validator = this.validator;
    if (!validator) return [];
    return validator(this.textElement.value);
  }
}
