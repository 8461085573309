import { DOMTemplate } from "../../denki";
import { buildDigitalForm } from "../../digitalForm";
import { DigitalApplicationDefinition } from "../definition/form/DigitalApplicationDefinition";
import { DigitalFormDefinition } from "../definition/form/DigitalFormDefinition";
import { DigitalForm } from "../DigitalForm";

export class DigitalFormViewController {
    public readonly view: HTMLElement;
    public readonly digitalForms: DigitalForm[] = [];
    private titles: string[] = [];
    private titleElement: HTMLElement;
    private container: HTMLElement;
    private index: number;

    constructor(formData: DigitalApplicationDefinition, view: HTMLElement, data) {
        this.view = view;
        this.titleElement = this.view.querySelector("[data-role=title]") as HTMLElement;
        this.container = view.getElementsByTagName("MAIN")[0] as HTMLElement;
        const map: { [keys: string]: DigitalForm } = {};
        const definitionMap: { [keys: string]: DigitalFormDefinition } = {};
        for (let i = 0, l = formData.forms.length; i < l; i++) {
            const formDefinition = formData.forms[i];
            const digitalForm = buildDigitalForm(
                formDefinition,
                new DOMTemplate(document.getElementById("template"))
            );
            digitalForm.data = data;
            map[formDefinition.id] = digitalForm;
            definitionMap[formDefinition.id] = formDefinition;
            this.digitalForms.push(digitalForm);
            this.titles.push(formDefinition.title);
        }
        if (formData.extraSetup) {
            formData.extraSetup(map, definitionMap);
        }
        this.index = -1;
    }

    get selectedIndex(): number {
        return this.index;
    }

    set selectedIndex(value: number) {
        const children = this.container.children;
        for (let i = 0; i < children.length; ++i) {
            this.container.removeChild(children[i]);
        }
        if (value >= 0 && value < this.digitalForms.length) {
            this.container.appendChild(this.digitalForms[value].element);
            if (this.titleElement) this.titleElement.textContent = this.titles[value];
            this.index = value;
        } else {
            this.index = -1;
        }
    }
}