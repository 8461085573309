import { FormElement } from "./FormElement";
import { DOMTemplate } from "../../denki";
import { node } from "../Util";
import { FieldInputElement } from "./FieldInputElement";
import zipcodeTable from "../../zipcode.json";
import { TemplateManager } from "../template/TemplateManager";

interface ZipcodeInfo {
  prefecture: string;
  city: string;
  address: string;
}

export type ZipcodeReferenceType = "raw" | "address" | "word" | "city_address";

interface ZipcodeReference {
  type: ZipcodeReferenceType;
  element: FieldInputElement;
}

export class FormZipCodeElement extends FormElement implements FieldInputElement {
  public readonly fieldsElement: HTMLElement;
  public readonly autoFillButton: HTMLButtonElement;
  public readonly first3Input: HTMLInputElement;
  public readonly plus4Input: HTMLInputElement;
  private readonly referers: ZipcodeReference[] = [];

  constructor(name: string, template: DOMTemplate) {
    super(name, template.get("field-element"));
    this.fieldsElement = this.element.querySelector("[data-role=elements]");
    const div = document.createElement("div");
    div.setAttribute("data-template", "input_zipcode");
    this.fieldsElement.appendChild(div);
    const id = name;
    this.virtualInput.setAttribute("data-id", id);
    this.virtualInput.setAttribute("name", id);
    this.first3Input = node('input', {
      type: 'text',
      name: "first_3",
      placeholder: "例：000",
      minlength: "3",
      maxlength: "3",
      "data-id": `${id}-first_3`,
      "data-label": "first_3",
      id: `form_element-${id}-first_3`
    });

    this.plus4Input = node("input", {
      type: "text",
      name: "plus_4",
      placeholder: "例：0000",
      minlength: "4",
      maxlength: "4",
      "data-id": `${id}-plus_4`,
      "data-label": "plus_4",
      id: `form_element-${id}-plus_4`
    });

    this.autoFillButton = node("button", {
      class: "autoInputButton"
    }, "住所を自動入力");

    div.appendChild(node("label", {}, "〒"));
    div.appendChild(this.first3Input);
    div.appendChild(node("label", {}, "-"));
    div.appendChild(this.plus4Input);
    div.appendChild(this.autoFillButton);

    this.virtualInput.addEventListener("change", () => {
      this.decodeValue();
    });

    let isFirst3InputEditing = false;
    this.first3Input.addEventListener("compositionstart", e => {
      isFirst3InputEditing = true;
    });
    this.first3Input.addEventListener("compositionend", e => {
      isFirst3InputEditing = false;
      this.encodeValue();
    });
    let isPlus4InputEditing = false;
    this.plus4Input.addEventListener("compositionstart", e => {
      isPlus4InputEditing = true;
    });
    this.plus4Input.addEventListener("compositionend", e => {
      isPlus4InputEditing = false;
      this.encodeValue();
    });
    ["paste", "cut", "change"].forEach(event => {
      this.first3Input.addEventListener(event, e => {
        if (!isFirst3InputEditing) {
          this.encodeValue();
        }
      });
      this.plus4Input.addEventListener(event, e => {
        if (!isPlus4InputEditing) {
          this.encodeValue();
        }
      });
    });
    this.autoFillButton.addEventListener("click", () => {
      const zipcode = this.value.replace("-", "");
      const zipcodeInfo = zipcodeTable[zipcode];
      if (zipcodeInfo) {
        this.referers.forEach(reference => this.refer(zipcodeInfo, reference));
      } else {
        alert("住所が見つかりませんでした");
      }
    });
  }

  private refer(zipcodeInfo: ZipcodeInfo, reference: ZipcodeReference) {
    const target = reference.element;
    const value = (() => {
      const city = zipcodeInfo.city;
      const address = zipcodeInfo.address;
      if (reference.type === "raw") return zipcodeInfo.prefecture + city + address;
      if (reference.type === "city_address") return city + address;
      if (reference.type === "address") return zipcodeInfo.address;
      if (reference.type === "word") {
        // 一旦パワープレイ
        // 市を名称に含む政令指定都市は現状ないのでセーフ
        const index = city.indexOf("市");
        return city.substring(index + 1);
      }
      if (reference.type === "word_name") {
        const index = city.indexOf("市");
        return city.substring(index + 1, city.length - 1);
      }
    })();
    target.value = value;
  }

  private encodeValue() {
    let first3 = TemplateManager.Util.convertToASCIIDigit(this.first3Input.value);
    const plus4 = TemplateManager.Util.convertToASCIIDigit(this.plus4Input.value);
    setTimeout(() => {
      // first3が埋まり切ってないと-が表示されてしまうので空白を詰めておく
      first3 = first3 + " ".repeat(3 - first3.length);
      if (first3 === "   " && !plus4) {
        this.value = "";
      } else {
        this.value = `${first3}-${plus4}`;
      }
    }, 0);
  }

  private decodeValue() {
    const value = this.value;
    if (value) {
      const token = value.split("-");
      this.first3Input.value = (token[0] || "").trim();
      this.plus4Input.value = (token[1] || "").trim();
    } else {
      this.first3Input.value = "";
      this.plus4Input.value = "";
    }
    this.updateValidation();
  }

  get validationErrors(): string[] {
    this.first3Input.classList.remove("error");
    this.plus4Input.classList.remove("error");

    let errors = [];
    const errorFirst = TemplateManager.Util.createValidatorDigit("上", 3)(this.first3Input.value);
    if (errorFirst.length > 0) {
      errors = errors.concat(errorFirst);
      this.first3Input.classList.add("error");
    }
    const errorPlus = TemplateManager.Util.createValidatorDigit("下", 4)(this.plus4Input.value);
    if (errorPlus.length > 0) {
      errors = errors.concat(errorPlus);
      this.plus4Input.classList.add("error");
    }
    return errors;
  }

  public focus() {
    this.first3Input.focus();
  }

  public addReferer(element: FieldInputElement, type: ZipcodeReferenceType) {
    this.referers.push({
      type, element
    });
  }

  public get mapKeys(): string[] {
    return ["first_3", "plus_4"];
  }

  public subInput(name: string) {
    if (name === "first_3") return this.first3Input;
    if (name === "plus_4") return this.plus4Input;
    return null;
  }
}
